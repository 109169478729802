import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "../../Dashboard/utils/useWindowDimensions";
import { useScrollYPosition } from "../../Dashboard/utils/useScrollYPosition";
import {
  Nav,
  Navbar,
  NavItem,
  NavbarToggler,
  NavbarBrand,
  Collapse,
} from "reactstrap";
import Logo from "../images/logo blu.png";
// import Scritta from "../images/scritta_gdp.webp";
import { useHistory } from "react-router-dom";
import { useMaxWidth } from "../utils/useMaxWidth";

export const NavbarComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width, height } = useWindowDimensions();
  const [rendered, setRendered] = useState(false);
  const scrollY = useScrollYPosition();
  const history = useHistory();
  const maxWidth = useMaxWidth();

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    setTimeout(() => {
      setRendered(true);
    }, 0);
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "red",
        zIndex: 9,
        backgroundColor: scrollY === 0 ? "transparent" : "white",
        // backgroundColor: "white",
        transition: rendered ? "0.5s" : "0s",
        boxShadow: scrollY > 0 ? "0 2px 13px -5px gray" : null,
        // boxShadow: "0 2px 10px -5px gray",
        padding: "5px 20px",
      }}
    >
      <Navbar
        id="navbarMain"
        expand="md"
        light
        style={{
          width: "100%",
          height: scrollY > 0 || width < 770 ? 65 : 120,
          // height: 60,
          transition: rendered ? "0.5s" : "0s",
          display: "flex",
          justifyContent: "center",
          maxWidth: maxWidth,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            id="parentCollapse"
            style={{
              width: "100%",
              display: width > 770 ? "flex" : "",
              alignItems: "center",
              height: "auto",
            }}
          >
            <NavbarBrand
              style={{ padding: 0, zIndex: 1, cursor: "pointer" }}
              onClick={() => {
                history.push("/");
              }}
            >
              <img
                src={Logo}
                alt="logo"
                style={{
                  // width: scrollY > 0 || width < 770 ? 32 : 38,
                  width: width < 770 ? 30 : 38,
                  marginTop: width < 770 ? "-9px" : "-10px",
                  // height: scrollY > 0 || width < 770 ? 32 : 38,
                  height: width < 770 ? 30 : 38,
                  // imageRendering: "-webkit-optimize-contrast",
                  transition: rendered ? "0.5s" : "0s",
                  cursor: "pointer",
                }}
              ></img>
              <span
                style={{
                  fontFamily: "Lato, sans-serif",
                  // fontSize: width > 770 ? 35 : 28,
                  fontSize: width < 770 ? 26 : 28,
                  fontWeight: 600,
                  color: "#33405e",
                  marginLeft: 10,
                }}
              >
                Eulero
              </span>
            </NavbarBrand>
            <NavbarToggler
              onClick={toggle}
              style={{
                float: "right",
                boxShadow: "none",
                fontSize: 15,
                padding: "4px 8px",
                marginTop: 3,
              }}
            />
            <Collapse
              navbar
              isOpen={isOpen}
              style={{
                backgroundColor:
                  width > 770 || width === 0 ? "transparent" : "white",
                border: width > 770 ? "" : "1px solid #F0F0F0",
                transition: isOpen === false ? "0s" : "0.3s",
                position: "absolute",
                width:
                  document.getElementById("parentCollapse") === null
                    ? "100%"
                    : document.getElementById("parentCollapse").offsetWidth,
              }}
            >
              <Nav
                className="me-auto"
                navbar
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 16,
                  color: "#313E5B",
                  fontWeight: 600,
                }}
              >
                <NavItem
                  style={{
                    width: "100%",
                    display: width > 770 ? "flex" : "",
                    justifyContent: width > 770 ? "end" : "start",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={width > 770 ? "hoverDesktop" : "hoverMobile"}
                    style={{ padding: "12px 20px" }}
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Home
                  </div>
                </NavItem>

                <NavItem
                  style={{
                    display: width > 770 ? "flex" : "",
                    justifyContent: width > 770 ? "end" : "start",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={width > 770 ? "hoverDesktop" : "hoverMobile"}
                    style={{ padding: "12px 20px" }}
                    onClick={() => {
                      history.push("/contatti");
                    }}
                  >
                    Contatti
                  </div>
                </NavItem>

                <NavItem
                  style={{
                    display: width > 770 ? "flex" : "",
                    justifyContent: width > 770 ? "end" : "start",
                    cursor: "pointer",
                  }}
                >
                  <div
                    data-testid="login-button"
                    className={width > 770 ? "hoverDesktop" : "hoverMobile"}
                    style={{ padding: "12px 20px", whiteSpace: "nowrap" }}
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    Login
                  </div>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
