import {
  GET_FILIALI,
  FILIALE_SELEZIONATA,
  CHANGE_DETAIL_FILIALE,
  CHANGE_LISTA_FILIALI,
  CREA_FILIALE,
} from "../actions/types";

// Initial personale redux state
const initialState = {
  listaFiliali: null,
  filialeSelezionata: {},
  creazioneFiliale: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Loading Personale
    case CREA_FILIALE:
      return {
        ...state,
        creazioneFiliale: {
          nome: "",
          indirizzo: "",
          provincia: null,
          comune: null,
        },
      };
    case GET_FILIALI:
      return {
        ...state,
        listaFiliali: action.payload,
      };
    case FILIALE_SELEZIONATA:
      return {
        ...state,
        filialeSelezionata: action.payload,
      };
    case CHANGE_LISTA_FILIALI:
      var lista = state.listaFiliali;
      if (action.payload.id === null) {
        lista = [action.payload.data, ...lista];
      } else {
        lista = lista.filter((x) => x.id !== action.payload.id);
      }
      return {
        ...state,
        listaFiliali: lista,
      };
    case CHANGE_DETAIL_FILIALE:
      const listaFiliali = state.listaFiliali.map((filiale) => {
        if (filiale.id === action.payload.id) {
          return {
            ...state[action.payload.tipo],
            ...action.payload.data,
          };
        } else return filiale;
      });
      return {
        ...state,
        listaFiliali: listaFiliali,
        [action.payload.tipo]: {
          ...state[action.payload.tipo],
          ...action.payload.data,
        },
      };

    default:
      return state;
  }
}
