import { PROVINCE, COMUNI } from "../actions/types";

const initialState = {
  listaProvince: [],
  listaComuni: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROVINCE:
      return {
        ...state,
        listaProvince: action.payload,
      };
    case COMUNI:
      return {
        ...state,
        listaComuni: action.payload,
      };

    default:
      return state;
  }
}
