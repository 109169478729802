export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const OPEN_SIDEBAR_WITHOUT_CLICK = "OPEN_SIDEBAR_WITHOUT_CLICK";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";
export const UPDATE_LAYOUT = "UPDATE_LAYOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_ERRORS = "GET_ERRORS";
export const IS_LOADING = "IS_LOADING";
export const PROVINCE = "PROVINCE";
export const COMUNI = "COMUNI";
export const CREA_AZIENDA = "CREA_AZIENDA";
export const GET_AZIENDE = "GET_AZIENDE";
export const CHANGE_LISTA_AZIENDE = "CHANGE_LISTA_AZIENDE";
export const AZIENDA_SELEZIONATA = "AZIENDA_SELEZIONATA";
export const CHANGE_DETAIL_AZIENDA = "CHANGE_DETAIL_AZIENDA";
export const CREA_FILIALE = "CREA_FILIALE";
export const FILIALE_SELEZIONATA = "FILIALE_SELEZIONATA";
export const CHANGE_DETAIL_FILIALE = "CHANGE_DETAIL_FILIALE";
export const CHANGE_LISTA_FILIALI = "CHANGE_LISTA_FILIALI";
export const GET_FILIALI = "GET_FILIALI";
export const GET_PERSONALE = "GET_PERSONALE";
export const PERSONA_SELEZIONATA = "PERSONA_SELEZIONATA";
export const CHANGE_DETAIL_PERSONA = "CHANGE_DETAIL_PERSONA";
export const CHANGE_LISTA_PERSONALE = "CHANGE_LISTA_PERSONALE";
export const GET_BUSTA_PAGA = "GET_BUSTA_PAGA";
export const AGGIUNGI_BUSTA_PAGA = "AGGIUNGI_BUSTA_PAGA";
export const DELETE_BUSTA_PAGA = "DELETE_BUSTA_PAGA";
export const MODIFICA_RAPPORTO = "MODIFICA_RAPPORTO";
export const DELETE_RAPPORTO = "DELETE_RAPPORTO";
export const CREA_PERSONA = "CREA_PERSONA";
export const GET_MEZZI = "GET_MEZZI";
export const MEZZO_SELEZIONATO = "MEZZO_SELEZIONATO";
export const CHANGE_DETAIL_MEZZO = "CHANGE_DETAIL_MEZZO";
export const CREA_MEZZO = "CREA_MEZZO";
export const CHANGE_LISTA_MEZZI = "CHANGE_LISTA_MEZZI";
export const AGGIUNGI_RAPPORTO = "AGGIUNGI_RAPPORTO";
export const GET_PRESENZE = "GET_PRESENZE";
export const AGGIUNGI_ELEMENTO = "AGGIUNGI_ELEMENTO";
export const MODIFICA_ELEMENTO = "MODIFICA_ELEMENTO";
export const DELETE_ELEMENTO = "DELETE_ELEMENTO";
export const GET_SCADENZE = "GET_SCADENZE";
export const GET_CONDUCENTI = "GET_CONDUCENTI";
export const ACCOUNT_AGGIUNTO = "ACCOUNT_AGGIUNTO";
export const ACCOUNT_RIMOSSO = "ACCOUNT_RIMOSSO";
export const AGGIUNGI_PATENTE = "AGGIUNGI_PATENTE";
export const MODIFICA_PATENTE = "MODIFICA_PATENTE";
export const DELETE_PATENTE = "DELETE_PATENTE";
export const AGGIUNGI_ALTRI_DOCUMENTI_PERSONA =
  "AGGIUNGI_ALTRI_DOCUMENTI_PERSONA";
export const DELETE_ALTRI_DOCUMENTI_PERSONA = "DELETE_ALTRI_DOCUMENTI_PERSONA";
export const GET_ALTRI_DOCUMENTI_PERSONA = "GET_ALTRI_DOCUMENTI_PERSONA";
export const AGGIUNGI_ALTRI_DOCUMENTI_MEZZO = "AGGIUNGI_ALTRI_DOCUMENTI_MEZZO";
export const DELETE_ALTRI_DOCUMENTI_MEZZO = "DELETE_ALTRI_DOCUMENTI_MEZZO";
export const GET_ALTRI_DOCUMENTI_MEZZO = "GET_ALTRI_DOCUMENTI_MEZZO";
export const GET_CAMPI_MEZZI_DOCUMENTO = "GET_CAMPI_MEZZI_DOCUMENTO";
export const AGGIUNGI_DOCUMENTO_PERSONA = "AGGIUNGI_DOCUMENTO_PERSONA";
export const AGGIUNGI_DOCUMENTO_MEZZO = "AGGIUNGI_DOCUMENTO_MEZZO";
export const AGGIUNGI_CAMPI_MEZZI_DOCUMENTI = "AGGIUNGI_CAMPI_MEZZI_DOCUMENTI";
export const GET_ELEMENTO = "GET_ELEMENTO";
export const MODIFICA_BUSTA_PAGA = "MODIFICA_BUSTA_PAGA";
export const MODIFICA_ALTRI_DOCUMENTI_PERSONA =
  "MODIFICA_ALTRI_DOCUMENTI_PERSONA";
export const MODIFICA_ALTRI_DOCUMENTI_MEZZO = "MODIFICA_ALTRI_DOCUMENTI_MEZZO";
export const DELETE_SINGOLO_DOCUMENTO_MEZZO = "DELETE_SINGOLO_DOCUMENTO_MEZZO";
export const DELETE_SINGOLO_DOCUMENTO_PERSONA =
  "DELETE_SINGOLO_DOCUMENTO_PERSONA";
export const GET_ASSOCIAZIONI_PRESENZE = "GET_ASSOCIAZIONI_PRESENZE";
export const AGGIUNGI_ASSOCIAZIONI_PRESENZE = "AGGIUNGI_ASSOCIAZIONI_PRESENZE";
export const MODIFICA_ASSOCIAZIONI_PRESENZE = "MODIFICA_ASSOCIAZIONI_PRESENZE";
export const DELETE_ASSOCIAZIONI_PRESENZE = "DELETE_ASSOCIAZIONI_PRESENZE";
export const UPDATE_TABLE_PROPERTY = "UPDATE_TABLE_PROPERTY";

// export const API_URL = "http://piattaforma-eulero.gdpanalytics.local:8000/api";
export const API_URL = "https://piattaforma-eulero.it/api";
