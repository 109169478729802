import {
  GET_AZIENDE,
  AZIENDA_SELEZIONATA,
  CHANGE_DETAIL_AZIENDA,
  CHANGE_LISTA_AZIENDE,
  CREA_AZIENDA,
} from "../actions/types";

// Initial personale redux state
const initialState = {
  listaAziende: null,
  aziendaSelezionata: {},
  creazioneAzienda: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Loading Personale
    case CREA_AZIENDA:
      return {
        ...state,
        creazioneAzienda: {
          ragione_sociale: "",
          partita_iva: "",
          codice_fiscale: "",
          indirizzo: "",
          provincia: null,
          comune: null,
          indirizzo_email: null,
        },
      };
    case GET_AZIENDE:
      return {
        ...state,
        listaAziende: action.payload,
      };
    case AZIENDA_SELEZIONATA:
      return {
        ...state,
        aziendaSelezionata: action.payload,
      };
    case CHANGE_LISTA_AZIENDE:
      var lista = state.listaAziende;
      if (action.payload.id === null) {
        lista = [action.payload.data, ...lista];
      } else {
        lista = lista.filter((x) => x.id !== action.payload.id);
      }
      return {
        ...state,
        listaAziende: lista,
      };
    case CHANGE_DETAIL_AZIENDA:
      const listaAziende = state.listaAziende.map((azienda) => {
        if (azienda.id === action.payload.id) {
          return {
            ...state[action.payload.tipo],
            ...action.payload.data,
          };
        } else return azienda;
      });
      return {
        ...state,
        listaAziende: listaAziende,
        [action.payload.tipo]: {
          ...state[action.payload.tipo],
          ...action.payload.data,
        },
      };

    default:
      return state;
  }
}
