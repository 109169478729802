import axios from "axios";
import SuccessAlert from "../components/Modals/SuccessAlert";
import { getErrors } from "../utils/getErrors";
import { tokenConfig } from "../utils/tokenConfig";

import { API_URL, IS_LOADING, GET_SCADENZE } from "./types";

export const getScadenze = () => async (dispatch) => {
  try {
    const scadenzeRequest = await axios.get(
      `${API_URL}/scadenze_documenti/`,
      tokenConfig()
    );
    dispatch({
      type: GET_SCADENZE,
      payload: scadenzeRequest.data,
    });
  } catch (err) {
    getErrors(err, dispatch);
  }
};
