import { useState, useEffect } from "react";
import { useWindowDimensions } from "../../Dashboard/utils/useWindowDimensions";

export const useMaxWidth = () => {
  const [maxWidth, setMaxWidth] = useState("1200px");
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if (width > 1600) {
      setMaxWidth("1500px");
    } else {
      setMaxWidth("1100px");
    }
  }, [width, height]);

  return maxWidth;
};
