// this menu can have up 3 levels
// icons are passed in level 1 through a string taken from react-icons (the mapping string/component is done in iconsmapped.js)
//  --> make sure to use only react-icons from Ant Design, Bootstrap Icons, Font Awesome and Material Design
// to create level 2 and 3 nest the array in the object using the key "children"
export const menusPrimarySidebarUser = [
  {
    label: "Homepage",
    url: "homepage",
    icon: "FaHome",
  },
  {
    label: "QR code",
    url: "qr_code",
    icon: "FaQrcode",
  },
  {
    label: "Profilo",
    url: "dipendenti/dettagli_persona",
    icon: "FaUser",
  },
  {
    label: "Scadenze",
    url: "scadenze/lista_scadenze",
    icon: "FaCalendar",
  },
];
