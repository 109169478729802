import React, { useEffect, lazy } from "react";
import store from "./store";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { loadUser } from "./Dashboard/actions/auth";
import PrivateRoute from "./Dashboard/layout/PrivateRoute";
import ModalsListener from "./Dashboard/components/ModalsListener/ModalsListener";
import ScrollToTop from "./Dashboard/components/ScrollToTop/ScrollToTop";
import Loading from "./Dashboard/layout/Loading";
import LandingPage1 from "./Website/routes/LandingPage1/LandingPage1";
import "./App.css";

// create history
const hist = createBrowserHistory();

// lazy load all routes in the application
const Homepage = lazy(() => import("./Website/routes/Homepage/Homepage"));
const Contatti = lazy(() => import("./Website/routes/Contatti/Contatti"));
const Login = lazy(() => import("./Website/routes/Login/Login"));
const RegistrazioneUser = lazy(() =>
  import("./Website/routes/RegistrazioneUser/RegistrazioneUser")
);
const RegistrazioneStaff = lazy(() =>
  import("./Website/routes/RegistrazioneStaff/RegistrazioneStaff")
);
const RecuperoPassword = lazy(() =>
  import("./Website/routes/RecuperoPassword/RecuperoPassword")
);
const PrivacyPolicy = lazy(() =>
  import("./Website/routes/PrivacyPolicy/PrivacyPolicy")
);
const CookiePolicy = lazy(() =>
  import("./Website/routes/CookiePolicy/CookiePolicy")
);
const Dashboard = lazy(() => import("./Dashboard/layout"));

export const App = () => {
  useEffect(() => {
    store.dispatch(loadUser(true));
    // document.title has been moved in index.js in layout
  }, []);
  return (
    <Provider store={store}>
      <Router history={hist}>
        <div className="App">
          <ModalsListener></ModalsListener>
          <ScrollToTop />
          <Switch>
            <React.Suspense fallback={<Loading></Loading>}>
              <Route exact path="/" component={Homepage}></Route>
              <Route exact path="/contatti" component={Contatti}></Route>
              <Route exact path="/login" component={Login}></Route>
              <Route
                exact
                path="/landing-page-1"
                component={LandingPage1}
              ></Route>
              <Route
                exact
                path="/privacy-policy"
                component={PrivacyPolicy}
              ></Route>
              <Route
                exact
                path="/cookie-policy"
                component={CookiePolicy}
              ></Route>
              <Route
                path="/registrazione_staff"
                component={RegistrazioneStaff}
              ></Route>
              <Route
                path="/registrazione_user"
                component={RegistrazioneUser}
              ></Route>
              <Route
                path="/password_reset"
                component={RecuperoPassword}
              ></Route>
              <PrivateRoute
                path="/dashboard"
                component={Dashboard}
              ></PrivateRoute>
            </React.Suspense>
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
