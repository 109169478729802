import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { useWindowDimensions } from "../../../../Dashboard/utils/useWindowDimensions";
import { useMaxWidth } from "../../../utils/useMaxWidth";
import { isEmailValid } from "../../../utils/isEmailValid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InfoAlert from "../../../../Dashboard/components/Modals/InfoAlert";
import { contatti } from "../../../../Dashboard/actions/auth";
import MdEmail from "../../../images/icons/MdEmail/MdEmail";
import { Link } from "react-router-dom";

const Contatti = (props) => {
  const { width, height } = useWindowDimensions();
  const [marginTop, setMarginTop] = useState("0px");
  const maxWidth = useMaxWidth();
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [azienda, setAzienda] = useState("");
  const [email, setEmail] = useState("");
  const [invalidemail, setInvalidemail] = useState(false);
  const [messaggio, setMessaggio] = useState(
    "Ciao! Vorrei richiedere maggiori informazioni."
  );
  const onChangeNome = (e) => setNome(e.target.value);
  const onChangeCognome = (e) => setCognome(e.target.value);
  const onChangeAzienda = (e) => setAzienda(e.target.value);
  const onChangeEmail = (e) => setEmail(e.target.value);
  const onChangeMessaggio = (e) => setMessaggio(e.target.value);
  const onSubmit = (e) => {
    e.preventDefault();
    if (nome === "") {
      InfoAlert("È necessario inserire un nome");
    } else if (cognome === "") {
      InfoAlert("È necessario inserire un cognome");
    } else if (azienda === "") {
      InfoAlert("È necessario inserire un'azienda");
    } else if (email === "") {
      InfoAlert("È necessario inserire una email");
    } else if (invalidemail === true) {
      InfoAlert("È necessario inserire un indirizzo email valido");
    } else {
      props.contatti({
        nome: nome,
        cognome: cognome,
        azienda: azienda,
        email: email,
        messaggio: messaggio,
      });
      setNome("");
      setCognome("");
      setAzienda("");
      setEmail("");
      setMessaggio("Ciao! Vorrei richiedere maggiori informazioni.");
    }
  };

  const sizeDescription = () => {
    if (width > 2100) {
      return 18;
    } else if (width > 1100) {
      return 17;
    } else if (width > 900) {
      return 16;
    } else if (width > 500) {
      return 14;
    } else {
      return 14;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: marginTop,
      }}
    >
      <div style={{}}>
        <Row
          style={{
            width: "100%",
            margin: 0,
            padding: 0,
            maxWidth: maxWidth,
          }}
        >
          <Col
            lg="6"
            md="6"
            sm="12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 40,
            }}
          >
            <form
              onSubmit={onSubmit}
              style={{
                padding: "30px",
                background: "#fefefe",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                paddingBottom: "20px",
                width: width > 900 ? "800px" : width > 700 ? "600px" : "100%",
              }}
            >
              <h4
                style={{
                  marginBottom: "20px",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Restiamo in contatto
              </h4>
              <p
                style={{
                  lineHeight: "155%",
                  marginBottom: "40px",
                  fontSize: "14px",
                  color: "#000",
                  opacity: "0.65",
                  fontWeight: "400",
                  maxWidth: "300px",
                }}
              >
                Se hai bisogno di supporto compila il form
              </p>
              <div
                style={{
                  position: "relative",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <TextField
                  sx={{ width: "100%" }}
                  type="name"
                  label={"Nome*"}
                  placeholder="Nome*"
                  onChange={onChangeNome}
                  value={nome}
                ></TextField>
              </div>
              <div
                style={{
                  position: "relative",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <TextField
                  sx={{ width: "100%" }}
                  type="surname"
                  label={"Cognome*"}
                  placeholder="Cognome*"
                  onChange={onChangeCognome}
                  value={cognome}
                ></TextField>
              </div>
              <div
                style={{
                  position: "relative",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <TextField
                  sx={{ width: "100%" }}
                  type="text"
                  label={"Azienda*"}
                  placeholder="Azienda*"
                  onChange={onChangeAzienda}
                  value={azienda}
                ></TextField>
              </div>
              <div
                style={{
                  position: "relative",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <TextField
                  sx={{ width: "100%" }}
                  type="email"
                  label={"Email*"}
                  error={invalidemail === true}
                  helperText={
                    invalidemail === true
                      ? "*Inserire un indirizzo email valido"
                      : ""
                  }
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setInvalidemail(false);
                    } else {
                      var match = isEmailValid(e.target.value);
                      if (match === null) {
                        setInvalidemail(true);
                      } else {
                        setInvalidemail(false);
                      }
                    }
                  }}
                  placeholder="Email*"
                  onChange={onChangeEmail}
                  value={email}
                ></TextField>
              </div>
              <div
                style={{
                  position: "relative",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <TextField
                  sx={{ width: "100%" }}
                  type="text"
                  label={"Messaggio"}
                  placeholder="Messaggio"
                  onChange={onChangeMessaggio}
                  value={messaggio}
                ></TextField>
              </div>
              <Button
                sx={{
                  placeSelf: "end",
                  margin: "10px 0px 10px 0px",
                  width: "100%",
                }}
                disabled={invalidemail}
                type="submit"
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Invia
              </Button>
            </form>
          </Col>
          <Col
            lg="6"
            md="6"
            sm="12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 40,
              padding: width > 770 ? 50 : 20,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: width < 770 ? "center" : "",
                fontSize: sizeDescription(),
                fontWeight: 400,
                color: "#6D768E",
                lineHeight: "29px",
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: sizeDescription() + 10,
                    fontWeight: "bold",
                  }}
                >
                  Registrati gratuitamente
                </div>
                <div style={{ marginTop: 20 }}>
                  Clicca <Link to="/registrazione_staff">qui</Link> per
                  registrarti <u>gratuitamente</u> ad Eulero.
                </div>
                <div
                  style={{
                    fontSize: sizeDescription() + 10,
                    fontWeight: "bold",
                    marginTop: 50,
                  }}
                >
                  Oppure contattaci
                </div>
                <div style={{ marginTop: 30 }}>
                  Se hai delle domande o hai bisogno di chiarimenti, puoi farlo
                  compilando il seguente modulo.
                  <div style={{ marginTop: 20 }}>
                    Saremo lieti di darti tutte le risposte che cerchi!
                  </div>
                </div>
                <div style={{ marginTop: 20 }}>
                  <MdEmail></MdEmail>
                  info@piattaforma-eulero.it
                </div>

                {/* <div style={{ marginTop: 20 }}>
                  <FaMapMarkerAlt></FaMapMarkerAlt>
                  Corso Unione Sovietica 341 - Torino
                </div> */}
                {/* <div style={{ marginTop: 40, fontSize: 14 }}>
                  <i>
                    Eulero è un marchio registrato di proprietà della società{" "}
                    <a target="_blank" href="https://www.gdpanalytics.com/">
                      GDP Analytics
                    </a>
                  </i>
                </div> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  contatti,
})(Contatti);
