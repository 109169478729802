import axios from "axios";
import SuccessAlert from "../components/Modals/SuccessAlert";
import { tokenConfig } from "../utils/tokenConfig";
import { getScadenze } from "./scadenze";
import {
  API_URL,
  IS_LOADING,
  GET_MEZZI,
  MEZZO_SELEZIONATO,
  CREA_MEZZO,
  CHANGE_LISTA_MEZZI,
  AGGIUNGI_ELEMENTO,
  MODIFICA_ELEMENTO,
  DELETE_ELEMENTO,
  CHANGE_DETAIL_MEZZO,
  AGGIUNGI_ALTRI_DOCUMENTI_MEZZO,
  DELETE_ALTRI_DOCUMENTI_MEZZO,
  GET_ALTRI_DOCUMENTI_MEZZO,
  AGGIUNGI_CAMPI_MEZZI_DOCUMENTI,
  GET_ELEMENTO,
  MODIFICA_ALTRI_DOCUMENTI_MEZZO,
  DELETE_SINGOLO_DOCUMENTO_MEZZO,
} from "./types";
import { getErrors } from "../utils/getErrors";
import { loadUser } from "./auth";

export const aggiungiMezzo = (body, history) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    const request = await axios.post(`${API_URL}/mezzi/`, body, tokenConfig());
    const data = request.data;
    dispatch({
      type: CHANGE_LISTA_MEZZI,
      payload: { id: null, data: data.mezzo },
    });
    for (const field in body) {
      if (field !== "mezzo") {
        if (body[field].length > 0) {
          dispatch(getScadenze());
          break;
        }
      }
    }
    dispatch({
      type: IS_LOADING,
    });
    dispatch(loadUser());
    history.push("/dashboard/mezzi/lista_mezzi");
    SuccessAlert("Hai aggiunto un nuovo mezzo");
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const getMezzi = () => async (dispatch) => {
  try {
    const mezziRequest = await axios.get(`${API_URL}/mezzi/`, tokenConfig());
    dispatch({
      type: GET_MEZZI,
      payload: mezziRequest.data,
    });
  } catch (err) {
    getErrors(err, dispatch);
  }
};

export const changeDetailMezzo =
  (group, id, body, type, isInUso = false, getConducentiBool = false) =>
  async (dispatch) => {
    try {
      var data = body;

      if (type === "details" && id !== undefined) {
        const request = await axios.put(
          `${API_URL}/mezzi/${id}/`,
          body,
          tokenConfig()
        );
        data = request.data;
      }

      var tipo =
        type === "details"
          ? "mezzoSelezionato"
          : type === "change" && id !== undefined
          ? "mezzoSelezionato"
          : "creazioneMezzo";
      dispatch({
        type: CHANGE_DETAIL_MEZZO,
        payload: { group, data, id, tipo },
      });
      if (type === "details" && isInUso === true && id !== undefined) {
        dispatch(getScadenze());
      }
    } catch (err) {
      dispatch(mezzoSelezionato(id, null, false));
      getErrors(err, dispatch);
    }
  };

export const mezzoSelezionato =
  (id, history, redirect = true) =>
  async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
      });
      const userRequest = await axios.get(
        `${API_URL}/mezzi/${id}/`,
        tokenConfig()
      );
      dispatch({
        type: MEZZO_SELEZIONATO,
        payload: userRequest.data,
      });
      dispatch({
        type: IS_LOADING,
      });
      if (redirect === true) {
        history.push("/dashboard/mezzi/dettagli_mezzo");
      }
    } catch (err) {
      dispatch({
        type: IS_LOADING,
      });
      getErrors(err, dispatch);
    }
  };

export const deleteMezzo = (id) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    await axios.delete(`${API_URL}/mezzi/${id}/`, tokenConfig());
    dispatch({
      type: CHANGE_LISTA_MEZZI,
      payload: { id },
    });
    dispatch(getScadenze());
    dispatch({
      type: IS_LOADING,
    });
    dispatch(loadUser());
    SuccessAlert("Mezzo eliminato correttamente");
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const creaMezzo = () => async (dispatch) => {
  dispatch({
    type: CREA_MEZZO,
  });
};

export const getElemento =
  (idMezzo, elemento, idElemento, show_loading = true) =>
  async (dispatch) => {
    try {
      if (show_loading === true) {
        dispatch({
          type: IS_LOADING,
        });
      }
      const request = await axios.get(
        `${API_URL}/mezzi/${idMezzo}/${elemento}/${idElemento}/`,
        tokenConfig()
      );
      dispatch({
        type: GET_ELEMENTO,
        payload: { request, elemento },
      });
      if (show_loading === true) {
        dispatch({
          type: IS_LOADING,
        });
      }
    } catch (err) {
      if (show_loading === true) {
        dispatch({
          type: IS_LOADING,
        });
      }
      getErrors(err, dispatch);
    }
  };
export const aggiungiElemento =
  (id, body, localBody, key) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
      });
      var data = body;
      if (id !== undefined) {
        const request = await axios.post(
          `${API_URL}/mezzi/${id}/${key}/`,
          localBody,
          tokenConfig()
        );

        var newKey = body[key].map((e) => {
          if (e.id === localBody.id) {
            return request.data;
          } else {
            return e;
          }
        });
        data = { ...body, [key]: newKey };
      }
      dispatch({
        type: AGGIUNGI_ELEMENTO,
        payload: { id, data },
      });
      if (id !== undefined) {
        dispatch(getScadenze());
      }
      dispatch({
        type: IS_LOADING,
      });
      dispatch(loadUser());
    } catch (err) {
      dispatch({
        type: IS_LOADING,
      });
      getErrors(err, dispatch);
    }
  };

export const modificaElemento =
  (id, idElemento, key, body) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
      });
      var newBody = body;
      if (id !== undefined) {
        const request = await axios.put(
          `${API_URL}/mezzi/${id}/${key}/${idElemento}/`,
          body,
          tokenConfig()
        );
        newBody = request.data;
      }
      dispatch({
        type: MODIFICA_ELEMENTO,
        payload: { id, idElemento, key, newBody },
      });
      dispatch(getScadenze());
      dispatch({
        type: IS_LOADING,
      });
      dispatch(loadUser());
    } catch (err) {
      dispatch({
        type: IS_LOADING,
      });
      getErrors(err, dispatch);
    }
  };
export const aggiungiDocumenti =
  (body, idMezzo, type, elemento, idElemento) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
      });
      var altriDocumenti = body.documenti;

      if (type === "details") {
        const request = await axios.post(
          `${API_URL}/mezzi/${idMezzo}/${elemento}/${idElemento}/documenti/`,
          altriDocumenti,
          tokenConfig()
        );
        altriDocumenti = request.data;
      }

      var group = type === "details" ? "mezzoSelezionato" : "creazioneMezzo";

      dispatch({
        type: AGGIUNGI_CAMPI_MEZZI_DOCUMENTI,
        payload: { altriDocumenti, group, elemento },
      });

      if (type === "details") {
        dispatch(getElemento(idMezzo, elemento, idElemento, false));
      }
      dispatch(loadUser());
      dispatch({
        type: IS_LOADING,
      });
      SuccessAlert("Hai aggiunto un nuovo Documento");
    } catch (err) {
      dispatch({
        type: IS_LOADING,
      });
      getErrors(err, dispatch);
    }
  };
export const deleteElemento = (id, idElemento, key) => async (dispatch) => {
  try {
    if (id !== undefined) {
      await axios.delete(
        `${API_URL}/mezzi/${id}/${key}/${idElemento}/`,
        tokenConfig()
      );
    }
    dispatch(getScadenze());
    dispatch({
      type: DELETE_ELEMENTO,
      payload: { id, idElemento, key },
    });
    dispatch(loadUser());
  } catch (err) {
    getErrors(err, dispatch);
  }
};
export const getDocumentoCampiMezzi =
  (idMezzo, elemento, idElemento, idDocumento) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
      });

      axios({
        url: `${API_URL}/mezzi/${idMezzo}/${elemento}/${idElemento}/documenti/${idDocumento}/`,
        ...tokenConfig(),
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const href = URL.createObjectURL(response.data);
        var fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        link.click();

        URL.revokeObjectURL(href);
      });

      dispatch({
        type: IS_LOADING,
      });
    } catch (err) {
      dispatch({
        type: IS_LOADING,
      });
      getErrors(err, dispatch);
    }
  };

export const aggiungiAltriDocumenti = (body, id, type) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    var altriDocumenti = body;

    if (type === "details") {
      const request = await axios.post(
        `${API_URL}/mezzi/${id}/altri_documenti/`,
        body,
        tokenConfig()
      );
      altriDocumenti = request.data;
    }
    var group = type === "details" ? "mezzoSelezionato" : "creazioneMezzo";
    dispatch({
      type: AGGIUNGI_ALTRI_DOCUMENTI_MEZZO,
      payload: { altriDocumenti, group },
    });
    if (type === "details") {
      dispatch(loadUser());
      dispatch(getScadenze());
    }
    dispatch({
      type: IS_LOADING,
    });
    SuccessAlert("Hai aggiunto un nuovo Documento");
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const modificaAltriDocumenti =
  (id, idAltroDocumento, body) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
      });
      var data = body;
      if (id !== undefined) {
        const request = await axios.put(
          `${API_URL}/mezzi/${id}/altri_documenti/${idAltroDocumento}/`,
          body,
          tokenConfig()
        );
        data = request.data;
      }
      const type = id !== undefined ? "mezzoSelezionato" : "creazioneMezzo";
      dispatch({
        type: MODIFICA_ALTRI_DOCUMENTI_MEZZO,
        payload: { data, idAltroDocumento, type },
      });
      dispatch({
        type: IS_LOADING,
      });
    } catch (err) {
      dispatch({
        type: IS_LOADING,
      });
      getErrors(err, dispatch);
    }
  };

export const deleteAltriDocumenti =
  (id, idAltriDocumenti) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
      });
      if (!String(idAltriDocumenti).includes("locale_")) {
        await axios.delete(
          `${API_URL}/mezzi/${id}/altri_documenti/${idAltriDocumenti}/`,
          tokenConfig()
        );
      }
      dispatch({
        type: DELETE_ALTRI_DOCUMENTI_MEZZO,
        payload: { id, idAltriDocumenti },
      });
      dispatch({
        type: IS_LOADING,
      });
      if (!String(idAltriDocumenti).includes("locale_")) {
        dispatch(loadUser());
        dispatch(getScadenze());
      }
      SuccessAlert("Hai rimosso con successo il documento");
    } catch (err) {
      getErrors(err, dispatch);
      dispatch({
        type: IS_LOADING,
      });
    }
  };

export const getAltriDocumenti = (id, idAltriDocumenti) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    const altriDocumenti = await axios.get(
      `${API_URL}/mezzi/${id}/altri_documenti/${idAltriDocumenti}/`,
      tokenConfig()
    );
    dispatch({
      type: GET_ALTRI_DOCUMENTI_MEZZO,
      payload: altriDocumenti.data,
    });
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const getDocumento =
  (id, idAltriDocumenti, idDocumento) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
      });
      axios({
        url: `${API_URL}/mezzi/${id}/altri_documenti/${idAltriDocumenti}/documenti/${idDocumento}/`,
        ...tokenConfig(),
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const href = URL.createObjectURL(response.data);
        var fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        link.click();

        URL.revokeObjectURL(href);
      });
      dispatch({
        type: IS_LOADING,
      });
    } catch (err) {
      dispatch({
        type: IS_LOADING,
      });
      getErrors(err, dispatch);
    }
  };

export const deleteSingoloDoc =
  (id, key, keyId, idDocumento) => async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
      });
      if (id !== undefined) {
        const request = await axios.delete(
          `${API_URL}/mezzi/${id}/${key}/${keyId}/documenti/${idDocumento}/`,
          tokenConfig()
        );
      }
      const type = id !== undefined ? "mezzoSelezionato" : "creazioneMezzo";

      dispatch({
        type: DELETE_SINGOLO_DOCUMENTO_MEZZO,
        payload: { id, type, key, keyId, idDocumento },
      });
      dispatch({
        type: IS_LOADING,
      });
      dispatch(loadUser());
      SuccessAlert("Hai eliminato il Documento");
    } catch (err) {
      dispatch({
        type: IS_LOADING,
      });
      getErrors(err, dispatch);
    }
  };
