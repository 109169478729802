import React from "react";
import IconAsImg from "./RiCheckboxCircleLine.png";

function RiCheckboxCircleLine(props) {
  return (
    <img
      src={IconAsImg}
      style={{ width: 15, height: 15, marginRight: 5 }}
    ></img>
  );
}

export default RiCheckboxCircleLine;
