// this menu can have up 3 levels
// icons are passed in level 1 through a string taken from react-icons (the mapping string/component is done in iconsmapped.js)
//  --> make sure to use only react-icons from Ant Design, Bootstrap Icons, Font Awesome and Material Design
// to create level 2 and 3 nest the array in the object using the key "children"
export const menusPrimarySidebarAdmin = [
  {
    label: "Homepage",
    url: "homepage",
    icon: "FaHome",
  },
  {
    label: "Aziende",
    url: "aziende/lista_aziende",
    icon: "FaRegBuilding",
  },
  {
    label: "Filiali",
    url: "filiali/lista_filiali",
    icon: "BsBuilding",
  },

  {
    label: "Dipendenti",
    icon: "FaUsers",
    children: [
      {
        label: "Lista",
        url: "dipendenti/lista_persone",
      },
      {
        label: "Presenze",
        url: "dipendenti/presenze",
      },
    ],
  },
  {
    label: "Mezzi",
    icon: "FaTruck",
    children: [
      {
        label: "Lista",
        url: "mezzi/lista_mezzi",
      },
      {
        label: "Conducenti",
        url: "mezzi/conducenti",
      },
    ],
  },
  {
    label: "Scadenze",
    url: "scadenze/lista_scadenze",
    icon: "FaCalendar",
  },
];
