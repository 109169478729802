import {
  GET_PERSONALE,
  PERSONA_SELEZIONATA,
  CHANGE_DETAIL_PERSONA,
  DELETE_BUSTA_PAGA,
  AGGIUNGI_RAPPORTO,
  MODIFICA_RAPPORTO,
  DELETE_RAPPORTO,
  CREA_PERSONA,
  AGGIUNGI_BUSTA_PAGA,
  CHANGE_LISTA_PERSONALE,
  ACCOUNT_AGGIUNTO,
  ACCOUNT_RIMOSSO,
  GET_BUSTA_PAGA,
  AGGIUNGI_PATENTE,
  MODIFICA_PATENTE,
  DELETE_PATENTE,
  AGGIUNGI_ALTRI_DOCUMENTI_PERSONA,
  DELETE_ALTRI_DOCUMENTI_PERSONA,
  GET_ALTRI_DOCUMENTI_PERSONA,
  AGGIUNGI_DOCUMENTO_PERSONA,
  MODIFICA_BUSTA_PAGA,
  MODIFICA_ALTRI_DOCUMENTI_PERSONA,
  DELETE_SINGOLO_DOCUMENTO_PERSONA,
} from "../actions/types";

// Initial personale redux state
const initialState = {
  listaPersonale: null,
  personaSelezionata: {},
  creazionePersona: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Loading Personale
    case GET_PERSONALE:
      return {
        ...state,
        listaPersonale: action.payload,
      };
    case PERSONA_SELEZIONATA:
      return {
        ...state,
        personaSelezionata: action.payload,
        creazionePersona: {},
      };
    case CREA_PERSONA:
      return {
        ...state,
        personaSelezionata: {},
        creazionePersona: {
          buste_paga: [],
          rapporti: [],
          patenti: [],
          altri_documenti: [],
          carta_identita: {
            numero_carta_identita: null,
            data_di_scadenza: null,
            foto_fronte: null,
            foto_retro: null,
          },
          persona: {
            nome: null,
            cognome: null,
            mansione: null,
            data_di_nascita: null,
            foto_profilo: null,
            email: null,
            account_registrato: false,
          },
          tessera_sanitaria: {
            codice_fiscale: null,
            data_di_scadenza: null,
            foto_fronte: null,
            foto_retro: null,
          },
        },
      };
    case CHANGE_DETAIL_PERSONA:
      const listaPersonale = state.listaPersonale.map((persona) => {
        if (persona.id === action.payload.id) {
          return {
            ...persona,
            ...action.payload.data[action.payload.group],
          };
        } else return persona;
      });
      return {
        ...state,
        listaPersonale: listaPersonale,
        [action.payload.tipo]: {
          ...state[action.payload.tipo],
          [action.payload.group]: {
            ...state[action.payload.tipo][action.payload.group],
            ...action.payload.data[action.payload.group],
          },
        },
      };
    case CHANGE_LISTA_PERSONALE:
      var lista = state.listaPersonale;
      if (action.payload.id === null) {
        lista = [action.payload.data, ...lista];
      } else {
        lista = lista.filter((x) => x.id !== action.payload.id);
      }
      return {
        ...state,
        listaPersonale: lista,
      };
    case AGGIUNGI_BUSTA_PAGA:
      return {
        ...state,
        [action.payload.group]: {
          ...state[action.payload.group],
          buste_paga: [
            ...state[action.payload.group].buste_paga,
            action.payload.bustaPaga,
          ],
        },
      };
    case DELETE_BUSTA_PAGA:
      var type;
      if (action.payload.id !== undefined) {
        type = "personaSelezionata";
      } else {
        type = "creazionePersona";
      }
      const listaBustePaga = state[type].buste_paga.filter(
        (bustaPaga) => bustaPaga.id !== action.payload.idBustaPaga
      );
      return {
        ...state,
        [type]: {
          ...state[type],
          buste_paga: listaBustePaga,
        },
      };
    case MODIFICA_BUSTA_PAGA:
      const listaBustePagaMod = state[action.payload.type].buste_paga.map(
        (bustaPaga) => {
          if (bustaPaga.id === action.payload.idBustaPaga) {
            return action.payload.data;
          } else return bustaPaga;
        }
      );
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          buste_paga: listaBustePagaMod,
        },
      };
    case GET_BUSTA_PAGA:
      const bustePaga = state.personaSelezionata.buste_paga.map((busta) => {
        if (busta.id === action.payload.id) {
          return action.payload;
        } else return busta;
      });
      return {
        ...state,
        personaSelezionata: {
          ...state.personaSelezionata,
          buste_paga: bustePaga,
        },
      };
    case AGGIUNGI_ALTRI_DOCUMENTI_PERSONA:
      return {
        ...state,
        [action.payload.group]: {
          ...state[action.payload.group],
          altri_documenti: [
            ...state[action.payload.group].altri_documenti,
            action.payload.altriDocumenti,
          ],
        },
      };
    case AGGIUNGI_DOCUMENTO_PERSONA:
      var type;
      if (action.payload.idPersona !== undefined) {
        type = "personaSelezionata";
      } else {
        type = "creazionePersona";
      }
      const altriDocumentiAggiunti = state[type].altri_documenti.map(
        (altroDocumento) => {
          if (
            altroDocumento.id ===
            action.payload.altriDocumenti[0].altro_documento
          ) {
            return {
              ...altroDocumento,
              documenti: [
                ...altroDocumento.documenti,
                ...action.payload.altriDocumenti,
              ],
            };
          } else return altroDocumento;
        }
      );
      return {
        ...state,
        [action.payload.group]: {
          ...state[action.payload.group],
          altri_documenti: altriDocumentiAggiunti,
        },
      };

    case DELETE_ALTRI_DOCUMENTI_PERSONA:
      var type;
      if (action.payload.id !== undefined) {
        type = "personaSelezionata";
      } else {
        type = "creazionePersona";
      }
      const listaAltriDocumenti = state[type].altri_documenti.filter(
        (altriDocumenti) =>
          altriDocumenti.id !== action.payload.idAltriDocumenti
      );
      return {
        ...state,
        [type]: {
          ...state[type],
          altri_documenti: listaAltriDocumenti,
        },
      };
    case MODIFICA_ALTRI_DOCUMENTI_PERSONA:
      const listaAltriDocumentiMod = state[
        action.payload.type
      ].altri_documenti.map((altroDocumento) => {
        if (altroDocumento.id === action.payload.idAltroDocumento) {
          return action.payload.data;
        } else return altroDocumento;
      });
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          altri_documenti: listaAltriDocumentiMod,
        },
      };
    case GET_ALTRI_DOCUMENTI_PERSONA:
      const altriDocumenti = state.personaSelezionata.altri_documenti.map(
        (documento) => {
          if (documento.id === action.payload.id) {
            return action.payload;
          } else return documento;
        }
      );
      return {
        ...state,
        personaSelezionata: {
          ...state.personaSelezionata,
          altri_documenti: altriDocumenti,
        },
      };
    case AGGIUNGI_RAPPORTO:
      return {
        ...state,
        [action.payload.tipo]: {
          ...state[action.payload.tipo],
          rapporti: [
            ...state[action.payload.tipo].rapporti,
            action.payload.rapporto,
          ],
        },
      };
    case MODIFICA_RAPPORTO:
      const listaRapporti = state[action.payload.type].rapporti.map(
        (rapporto) => {
          if (rapporto.id === action.payload.idRapporto) {
            return action.payload.data;
          } else return rapporto;
        }
      );
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          rapporti: listaRapporti,
        },
      };
    case DELETE_RAPPORTO:
      var type;
      if (action.payload.id !== undefined) {
        type = "personaSelezionata";
      } else {
        type = "creazionePersona";
      }
      const listaRapportiDel = state[type].rapporti.filter(
        (rapporto) => rapporto.id != action.payload.idRapporto
      );
      return {
        ...state,
        [type]: {
          ...state[type],
          rapporti: listaRapportiDel,
        },
      };
    case AGGIUNGI_PATENTE:
      return {
        ...state,
        [action.payload.tipo]: {
          ...state[action.payload.tipo],
          patenti: [
            ...state[action.payload.tipo].patenti,
            action.payload.patente,
          ],
        },
      };
    case DELETE_PATENTE:
      var type;
      if (action.payload.id !== undefined) {
        type = "personaSelezionata";
      } else {
        type = "creazionePersona";
      }
      const listaPatentiDel = state[type].patenti.filter(
        (patente) => patente.id != action.payload.idPatente
      );
      return {
        ...state,
        [type]: {
          ...state[type],
          patenti: listaPatentiDel,
        },
      };
    case MODIFICA_PATENTE:
      const listaPatenti = state[action.payload.type].patenti.map((patente) => {
        if (patente.id === action.payload.idPatente) {
          return action.payload.data;
        } else return patente;
      });
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          patenti: listaPatenti,
        },
      };
    case ACCOUNT_AGGIUNTO:
      return {
        ...state,
        personaSelezionata: {
          ...state.personaSelezionata,
          persona: {
            ...state.personaSelezionata.persona,
            account_registrato: true,
          },
        },
      };
    case ACCOUNT_RIMOSSO:
      return {
        ...state,
        personaSelezionata: {
          ...state.personaSelezionata,
          persona: {
            ...state.personaSelezionata.persona,
            account_registrato: false,
          },
        },
      };
    case DELETE_SINGOLO_DOCUMENTO_PERSONA:
      var lista = state[action.payload.type].altri_documenti.map((x) => {
        if (x.id === action.payload.keyId) {
          var documenti = [];
          if (x.documenti.length > 0) {
            x.documenti.forEach((element) => {
              if (element.id !== action.payload.idDocumento) {
                documenti.push(element);
              }
            });
          }
          x.nr_documenti = x.nr_documenti - 1;
          x.documenti = documenti;
        }
        return x;
      });

      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          altri_documenti: lista,
        },
      };
    default:
      return state;
  }
}
