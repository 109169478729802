import { GET_SCADENZE } from "../actions/types";

// Initial personale redux state
const initialState = {
  listaScadenze: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Loading Personale
    case GET_SCADENZE:
      return {
        ...state,
        listaScadenze: action.payload,
      };
    default:
      return state;
  }
}
