import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useWindowDimensions } from "../../../Dashboard/utils/useWindowDimensions";
import Background from "../../images/bg.jpg";
import NavbarComponent from "../../layout/Navbar";
import { useMaxWidth } from "../../utils/useMaxWidth";
import HomepagePic from "../../images/homepagePic.png";
import RiCheckboxCircleLine from "../../images/icons/RiCheckboxCircleLine/RiCheckboxCircleLine";
import EmptyPC from "../../images/empty_pc.png";
import Anagrafiche from "../../images/anagrafiche.png";
import Presenze from "../../images/presenze.png";
import Scadenziario from "../../images/scadenziario.png";
import PageContatti from "../Contatti/components/PageContatti";
import Footer from "../../layout/Footer";
import { sendP } from "../../actions/sendP";
import { connect } from "react-redux";

const LandingPage1 = (props) => {
  const { width, height } = useWindowDimensions();
  const [marginTop, setMarginTop] = useState("0px");
  const maxWidth = useMaxWidth();
  const location = useLocation();

  // send p on mount
  useEffect(() => {
    var p;
    try {
      var p = new URLSearchParams(location.search).get("p");
    } catch {
      p = location.search.substring(1).split("p=")[1];
    }

    props.sendP(p);
  }, []);

  useEffect(() => {
    if (document.getElementById("introHomepage") !== null) {
      setMarginTop(
        `${document.getElementById("introHomepage").offsetHeight}px`
      );
    }
  }, [width, height]);

  const sizeDescription = () => {
    if (width > 2100) {
      return 18;
    } else if (width > 1100) {
      return 17;
    } else if (width > 900) {
      return 16;
    } else if (width > 500) {
      return 14;
    } else {
      return 14;
    }
  };

  return (
    <div className="websitePageContainer">
      <NavbarComponent></NavbarComponent>

      <div
        id="introHomepage"
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          // height: width < 770 ? height : height - 200,
          height: height,
          padding: "200px 25px 150px 25px",
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <Row
          style={{
            width: "100%",
            margin: 0,
            padding: 0,
            maxWidth: maxWidth,
          }}
        >
          <Col lg="6" md="6" sm="12">
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: width < 770 ? "center" : "",
              }}
            >
              <div>
                <div
                  style={{
                    fontFamily: "Gill Sans, sans-serif",
                    fontSize:
                      width > 2100
                        ? 45
                        : width > 1100
                        ? 38
                        : width > 900
                        ? 35
                        : width > 500
                        ? 30
                        : 23,
                    fontWeight: 600,
                    color: "#3D4861",
                    // animationDelay: "0.4s",
                  }}
                >
                  Gestisci la tua azienda<br></br> di trasporti in cloud
                </div>
                <div
                  style={{
                    fontSize:
                      width > 2100
                        ? 18
                        : width > 1100
                        ? 17
                        : width > 900
                        ? 16
                        : width > 500
                        ? 14
                        : 13,
                    fontWeight: 400,
                    color: "#6D768E",
                    lineHeight: "29px",
                    marginTop: 20,
                    // animationDelay: "1.3s",
                  }}
                >
                  {" "}
                  Semplifica ed automatizza l'amministrazione delle{" "}
                  {width > 400 ? <br></br> : null}
                  aziende di trasporti, senza alcuna installazione!
                </div>
                <div
                  style={{
                    marginTop: 40,
                    // animationDelay: "1.6s"
                  }}
                >
                  <Link to={`/registrazione_staff`}>
                    <Button
                      style={{
                        fontSize: width > 900 ? 16 : width > 500 ? 14 : 13,
                        color: "white",
                        backgroundColor: "#437EEB",
                        border: "none",
                        padding: width > 770 ? "13px 25px" : "10px 18px",
                        borderRadius: 10,
                        boxShadow: "none",
                        fontWeight: 600,
                      }}
                    >
                      Registrati, è gratis!
                    </Button>
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    color: "#6D768E",
                    fontSize:
                      width > 2100
                        ? 16
                        : width > 1100
                        ? 15
                        : width > 900
                        ? 14
                        : width > 500
                        ? 13
                        : 12,
                  }}
                >
                  <div>
                    <RiCheckboxCircleLine></RiCheckboxCircleLine>{" "}
                    {width < 400 ? "Gratuito" : "100% gratuito"} per PMI
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <RiCheckboxCircleLine></RiCheckboxCircleLine>{" "}
                    {width < 400 ? "No" : "Nessuna"} installazione
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg="6"
            md="6"
            sm="12"
            style={{
              display: width < 770 ? "none" : "",
              padding: width < 1600 ? 60 : 100,
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={EmptyPC}
                  alt=""
                  style={{
                    width: "100%",
                    position: "absolute",
                    marginTop: 17,
                    imageRendering: "-webkit-optimize-contrast",
                  }}
                />

                <img
                  // id="vid"
                  src={HomepagePic}
                  style={{
                    width: "78%",
                    imageRendering: "-webkit-optimize-contrast",
                  }}
                ></img>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div
        style={{
          width: "100%",

          marginTop: marginTop,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 5,
          }}
        >
          <div style={{ maxWidth: maxWidth }}>
            <Row
              style={{
                width: "100%",
                margin: 0,
                padding: 0,
              }}
            >
              <Col
                lg="6"
                md="6"
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 30,
                }}
              >
                <img
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={Anagrafiche}
                  alt=""
                />
              </Col>
              <Col
                lg="6"
                md="6"
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 50,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: width < 770 ? "center" : "",
                    fontSize: sizeDescription(),
                    fontWeight: 400,
                    color: "#6D768E",
                    lineHeight: "29px",
                  }}
                >
                  <div>
                    <div
                      style={{
                        fontSize: sizeDescription() + 10,
                        fontWeight: "bold",
                      }}
                    >
                      Gestione Anagrafiche
                    </div>
                    <div style={{ marginTop: 30 }}>
                      Gestire le anagrafiche è alla base di una amministrazione
                      efficiente dell'azienda. Una corretta gestione è
                      indispensabile per mantenere sempre aggiornate tutte le
                      informazioni e i dati necessari alla gestione operativa di
                      un'impresa sia sotto il profilo fiscale che
                      amministrativo.
                    </div>
                    <div style={{ marginTop: 20, fontSize: sizeDescription() }}>
                      <Row>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          Anagrafica Aziende
                        </Col>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          Anagrafica Filiali
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 5 }}>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          Anagrafica Personale
                        </Col>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          Anagrafica Mezzi
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row
              style={{
                width: "100%",
                margin: 0,
                padding: 0,
              }}
            >
              <Col
                lg="6"
                md="6"
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 50,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: width < 770 ? "center" : "",
                    fontSize: sizeDescription(),
                    fontWeight: 400,
                    color: "#6D768E",
                    lineHeight: "29px",
                  }}
                >
                  <div>
                    <div
                      style={{
                        fontSize: sizeDescription() + 10,
                        fontWeight: "bold",
                      }}
                    >
                      Registrazione presenze
                    </div>
                    <div style={{ marginTop: 30 }}>
                      Basta excel e mail per la registrazione delle presenze.
                      Grazie a Eulero è possibile gestire il proprio personale
                      in cloud con la possibilità di coordinare permessi, ferie
                      e malattie. Organizza e visualizza i dati sia da computer
                      che smartphone e condividi i documenti con il tuo
                      personale.
                    </div>
                    <div style={{ marginTop: 20, fontSize: sizeDescription() }}>
                      <Row>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          {width > 400 ? "Registrazione presenze" : "Presenze"}
                        </Col>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          Mezzi e conducenti
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 5 }}>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          Export dei file
                        </Col>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          Analisi dati storici
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                lg="6"
                md="6"
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 30,
                }}
              >
                <img
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={Presenze}
                  alt=""
                />
              </Col>
            </Row>
            <Row
              style={{
                width: "100%",
                margin: 0,
                padding: 0,
              }}
            >
              <Col
                lg="6"
                md="6"
                sm="12"
                style={{
                  display: width > 770 ? "flex" : "none",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                <img
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={Scadenziario}
                  alt=""
                />
              </Col>
              <Col
                lg="6"
                md="6"
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: width < 770 ? "center" : "",
                    fontSize: sizeDescription(),
                    fontWeight: 400,
                    color: "#6D768E",
                    lineHeight: "29px",
                  }}
                >
                  <div>
                    <div
                      style={{
                        fontSize: sizeDescription() + 10,
                        fontWeight: "bold",
                      }}
                    >
                      Scadenziario
                    </div>
                    <div style={{ marginTop: 30 }}>
                      Ogni azienda deve gestire quotidianamente decine di
                      scadenze: assicurazioni, revisioni, patenti, manutenzioni,
                      carte d'identità. Con Eulero tutte le scadenze vengono
                      automaticamente tracciate in un unico strumento,
                      centralizzato, intuitivo e accedibile ovunque, senza
                      alcuna installazione richiesta.
                    </div>
                    <div style={{ marginTop: 20, fontSize: sizeDescription() }}>
                      <Row>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          {width < 370 ? "Doc." : "Documenti"} personale
                        </Col>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          Documenti mezzi
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 5 }}>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          Alert periodici
                        </Col>
                        <Col xs="6">
                          <RiCheckboxCircleLine></RiCheckboxCircleLine>
                          Verifica automatica
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={{ margin: 0, padding: 20 }}>
              <Link to="/registrazione_staff">
                <Button
                  color="primary"
                  style={{
                    marginTop: width > 770 ? 40 : 60,
                    padding: 20,
                    borderRadius: 30,
                    cursor: "default",
                    boxShadow: "none",
                    backgroundColor: "#1976D2",
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  {width > 450 ? (
                    <div>
                      Sei una PMI? Il 100% di Eulero è <b>gratis!</b>
                    </div>
                  ) : (
                    <div>
                      Registrati, è <b>gratis!</b>
                    </div>
                  )}
                </Button>
              </Link>
            </Row>
            <div style={{ marginTop: 20 }}>
              <PageContatti></PageContatti>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default connect(null, { sendP })(LandingPage1);
