import React from "react";
import IconAsImg from "./FaCookie.png";

function FaCookie(props) {
  return (
    <img
      src={IconAsImg}
      style={{ width: 18, height: 18, marginRight: 5 }}
    ></img>
  );
}

export default FaCookie;
