import axios from "axios";
import { API_URL } from "../../Dashboard/actions/types";

export const sendP = (p) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(p);
  try {
    const res = await axios.post(`${API_URL}/landing-page/`, body, config);
  } catch {}
};
