import { id } from "date-fns/locale";
import { mezzoSelezionato } from "../actions/mezzi";
import {
  GET_MEZZI,
  MEZZO_SELEZIONATO,
  CREA_MEZZO,
  CHANGE_LISTA_MEZZI,
  AGGIUNGI_ELEMENTO,
  MODIFICA_ELEMENTO,
  DELETE_ELEMENTO,
  CHANGE_DETAIL_MEZZO,
  AGGIUNGI_ALTRI_DOCUMENTI_MEZZO,
  DELETE_ALTRI_DOCUMENTI_MEZZO,
  GET_ALTRI_DOCUMENTI_MEZZO,
  AGGIUNGI_CAMPI_MEZZI_DOCUMENTI,
  GET_ELEMENTO,
  AGGIUNGI_DOCUMENTO_MEZZO,
  MODIFICA_ALTRI_DOCUMENTI_MEZZO,
  DELETE_SINGOLO_DOCUMENTO_MEZZO,
} from "../actions/types";

// Initial personale redux state
const initialState = {
  listaMezzi: null,
  mezzoSelezionato: {},
  creazioneMezzo: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MEZZI:
      return {
        ...state,
        listaMezzi: action.payload,
      };
    case CREA_MEZZO:
      return {
        ...state,
        creazioneMezzo: {
          mezzo: {
            targa: null,
            marca: null,
            modello: null,
            alimentanzione: null,
            tipologia_veicolo: null,
            euro: null,
            data_di_dismissione: null,
            data_prima_immatricolazione: null,
            foto_mezzo: null,
            azienda: null,
            filiale: null,
          },
          revisioni: [],
          assicurazioni: [],
          manutenzioni: [],
          verbali: [],
          sinistri: [],
          bolli: [],
          altri_documenti: [],
        },
      };
    case CHANGE_DETAIL_MEZZO:
      const listaMezzi = state.listaMezzi.map((mezzo) => {
        if (mezzo.id === action.payload.id) {
          return { ...mezzo, ...action.payload.data[action.payload.group] };
        } else return mezzo;
      });
      return {
        ...state,
        listaMezzi: listaMezzi,
        [action.payload.tipo]: {
          ...state[action.payload.tipo],
          [action.payload.group]: {
            ...state[action.payload.tipo][action.payload.group],
            ...action.payload.data[action.payload.group],
          },
        },
      };
    case CHANGE_LISTA_MEZZI:
      var lista = state.listaMezzi;
      if (action.payload.id === null) {
        lista = [action.payload.data, ...lista];
      } else {
        lista = lista.filter((x) => x.id !== action.payload.id);
      }
      return {
        ...state,
        listaMezzi: lista,
      };
    case MEZZO_SELEZIONATO:
      return {
        ...state,
        mezzoSelezionato: action.payload,
      };
    case GET_ELEMENTO:
      const elementi = state.mezzoSelezionato[action.payload.elemento].map(
        (el) => {
          if (el.id === action.payload.request.data.id) {
            return action.payload.request.data;
          } else {
            return el;
          }
        }
      );
      return {
        ...state,
        mezzoSelezionato: {
          ...state.mezzoSelezionato,
          [action.payload.elemento]: elementi,
        },
      };
    case AGGIUNGI_ELEMENTO:
      var chiave = "mezzoSelezionato";
      if (action.payload.id === undefined) {
        chiave = "creazioneMezzo";
      }
      return {
        ...state,
        [chiave]: action.payload.data,
      };
    case MODIFICA_ELEMENTO:
      var chiave = "mezzoSelezionato";
      if (action.payload.id === undefined) {
        chiave = "creazioneMezzo";
      }
      var lista = state[chiave][action.payload.key].map((x) => {
        if (x.id !== action.payload.idElemento) {
          return x;
        } else {
          return action.payload.newBody;
        }
      });
      return {
        ...state,
        [chiave]: {
          ...state[chiave],
          [action.payload.key]: lista,
        },
      };

    case DELETE_ELEMENTO:
      var chiave = "mezzoSelezionato";
      if (String(action.payload.idElemento).includes("locale_")) {
        chiave = "creazioneMezzo";
      }
      var lista = state[chiave][action.payload.key].filter(
        (x) => x.id !== action.payload.idElemento
      );
      return {
        ...state,
        [chiave]: {
          ...state[chiave],
          [action.payload.key]: lista,
        },
      };
    case AGGIUNGI_CAMPI_MEZZI_DOCUMENTI:
      var dictSingolari = {
        revisioni: "revisione",
        bolli: "bollo",
        assicurazioni: "assicurazione",
        manutenzioni: "manutenzione",
        verbali: "verbale",
        sinistri: "sinistro",
        altri_documenti: "altro_documento",
      };
      const altriDocumentiAggiunti = state[action.payload.group][
        action.payload.elemento
      ].map((altroDocumento) => {
        if (
          altroDocumento.id ===
          action.payload.altriDocumenti[0][
            dictSingolari[action.payload.elemento]
          ]
        ) {
          return {
            ...altroDocumento,
            documenti: [
              ...altroDocumento.documenti,
              ...action.payload.altriDocumenti,
            ],
          };
        } else return altroDocumento;
      });
      return {
        ...state,
        [action.payload.group]: {
          ...state[action.payload.group],
          [action.payload.elemento]: altriDocumentiAggiunti,
        },
      };
    case AGGIUNGI_ALTRI_DOCUMENTI_MEZZO:
      return {
        ...state,
        [action.payload.group]: {
          ...state[action.payload.group],
          altri_documenti: [
            ...state[action.payload.group].altri_documenti,
            action.payload.altriDocumenti,
          ],
        },
      };
    case MODIFICA_ALTRI_DOCUMENTI_MEZZO:
      const listaAltriDocumentiMod = state[
        action.payload.type
      ].altri_documenti.map((altroDocumento) => {
        if (altroDocumento.id === action.payload.idAltroDocumento) {
          return action.payload.data;
        } else return altroDocumento;
      });
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          altri_documenti: listaAltriDocumentiMod,
        },
      };
    case DELETE_ALTRI_DOCUMENTI_MEZZO:
      var type;
      if (action.payload.id !== undefined) {
        type = "mezzoSelezionato";
      } else {
        type = "creazioneMezzo";
      }
      const listaAltriDocumenti = state[type].altri_documenti.filter(
        (altriDocumenti) =>
          altriDocumenti.id !== action.payload.idAltriDocumenti
      );
      return {
        ...state,
        [type]: {
          ...state[type],
          altri_documenti: listaAltriDocumenti,
        },
      };
    case AGGIUNGI_DOCUMENTO_MEZZO:
      var type;
      if (action.payload.idMezzo !== undefined) {
        type = "mezzoSelezionato";
      } else {
        type = "creazioneMezzo";
      }
      const docs = state[type].altri_documenti.map((altroDocumento) => {
        if (
          altroDocumento.id === action.payload.altriDocumenti[0].altro_documento
        ) {
          return {
            ...altroDocumento,
            documenti: [
              ...altroDocumento.documenti,
              ...action.payload.altriDocumenti,
            ],
          };
        } else return altroDocumento;
      });
      return {
        ...state,
        [action.payload.group]: {
          ...state[action.payload.group],
          altri_documenti: docs,
        },
      };
    case GET_ALTRI_DOCUMENTI_MEZZO:
      const altriDocumenti = state.mezzoSelezionato.altri_documenti.map(
        (documento) => {
          if (documento.id === action.payload.id) {
            return action.payload;
          } else return documento;
        }
      );
      return {
        ...state,
        mezzoSelezionato: {
          ...state.mezzoSelezionato,
          altri_documenti: altriDocumenti,
        },
      };
    case DELETE_SINGOLO_DOCUMENTO_MEZZO:
      var lista = state[action.payload.type][action.payload.key].map((x) => {
        if (x.id === action.payload.keyId) {
          var documenti = [];
          if (x.documenti.length > 0) {
            x.documenti.forEach((element) => {
              if (element.id !== action.payload.idDocumento) {
                documenti.push(element);
              }
            });
          }
          x.nr_documenti = x.nr_documenti - 1;
          x.documenti = documenti;
        }
        return x;
      });

      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          [action.payload.key]: lista,
        },
      };
    default:
      return state;
  }
}
