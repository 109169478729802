import { combineReducers } from "redux";
import auth from "./auth";
import layout from "./layout";
import errors from "./errors";
import isLoading from "./isLoading";
import geografia from "./geografia";
import aziende from "./aziende";
import filiali from "./filiali";
import personale from "./personale";
import mezzi from "./mezzi";
import scadenze from "./scadenze";
import tablesProperties from "./tablesProperties";

export default combineReducers({
  auth,
  layout,
  errors,
  isLoading,
  geografia,
  aziende,
  filiali,
  personale,
  mezzi,
  scadenze,
  tablesProperties,
});
