import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isLoading) {
          return <h2>Loading...</h2>;
        }
        // else if (auth.isAuthenticated === null) {
        //   // if null means that you refreshed and that you have lost the store. In this case proceed and in the Dashboard component you monitor the authentication
        //   return <Component {...props}></Component>;
        // }
        else if (!auth.isAuthenticated) {
          return <Redirect to="/login"></Redirect>;
        } else {
          return <Component {...props}></Component>;
        }
      }}
    ></Route>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
