import React from "react";
import { Row, Col } from "reactstrap";
import { useWindowDimensions } from "../../Dashboard/utils/useWindowDimensions";
import MdEmail from "../images/icons/MdEmail/MdEmail";
import FaLock from "../images/icons/FaLock/FaLock";
import FaCookie from "../images/icons/FaCookie/FaCookie";
import Logo from "../images/logo blu.png";
import { useHistory } from "react-router-dom";
// import Router from "next/router";

export const Footer = (props) => {
  const { width, height } = useWindowDimensions();
  const history = useHistory();

  return (
    <footer style={{ backgroundColor: "#eff1fe", marginTop: 100 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          padding: "60px 0px 20px 0px",
        }}
      >
        <div>
          <Row
            style={{
              width: width / 1.2 < 1300 ? width / 1.2 : 1300,
              margin: 0,
            }}
          >
            <Col
              lg="5"
              md="6"
              sm="12"
              style={{
                padding: 20,
                paddingRight: width > 770 ? 70 : 20,
              }}
            >
              <img
                src={Logo}
                alt="logo"
                style={{
                  width: 40,
                  height: 40,
                  imageRendering: "-webkit-optimize-contrast",
                  marginTop: "-10px",
                }}
              ></img>
              <span
                style={{
                  fontFamily: "Lato, sans-serif",
                  // fontSize: width > 770 ? 35 : 28,
                  fontSize: width < 770 ? 26 : 28,
                  fontWeight: 600,
                  color: "#33405e",
                  marginLeft: 10,
                }}
              >
                Eulero
              </span>
              <div style={{ color: "#697085", marginTop: 20, fontSize: 15 }}>
                Knowledge drives innovation, innovation drives productivity and
                productivity drives economic growth.
              </div>
            </Col>
            <Col
              lg="3"
              md="6"
              sm="12"
              style={{
                padding: 20,
              }}
            >
              <div
                style={{
                  color: "#313E5B",
                  fontSize: 25,
                  fontWeight: 600,
                  marginBottom: 20,
                }}
              >
                Menu
              </div>
              <div
                className="blueTextOnHover"
                style={{ marginTop: 15, color: "#697085", cursor: "pointer" }}
                onClick={() => {
                  history.push("/");
                }}
              >
                Home
              </div>

              <div
                className="blueTextOnHover"
                style={{ marginTop: 15, color: "#697085", cursor: "pointer" }}
                onClick={() => {
                  history.push("/contatti");
                }}
              >
                Contatti
              </div>
              <div
                className="blueTextOnHover"
                style={{ marginTop: 15, color: "#697085", cursor: "pointer" }}
                onClick={() => {
                  history.push("/login");
                }}
              >
                Login
              </div>
            </Col>

            <Col
              lg="4"
              md="6"
              sm="12"
              style={{
                padding: 20,
                // backgroundColor: "yellow",
              }}
            >
              <div
                style={{
                  color: "#313E5B",
                  fontSize: 25,
                  fontWeight: 600,
                  marginBottom: 20,
                }}
              >
                Contact us
              </div>
              <div
                style={{
                  color: "#313E5B",
                  fontSize: 18,
                  display: "flex",
                  marginTop: 23,
                }}
              >
                <MdEmail></MdEmail>
                <div
                  style={{
                    color: "#697085",
                    fontSize: 16,
                    marginTop: "-3px",
                    marginLeft: 10,
                  }}
                >
                  <a href="mailto: info@piattaforma-eulero.it">
                    info@piattaforma-eulero.it
                  </a>
                </div>
              </div>

              <div
                style={{
                  color: "#313E5B",
                  fontSize: 18,
                  display: "flex",
                  marginTop: 20,
                }}
              >
                <FaLock></FaLock>
                <div
                  className="blueTextOnHover"
                  style={{
                    color: "#697085",
                    fontSize: 16,
                    marginTop: "-3px",
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </div>
              </div>
              <div
                style={{
                  color: "#313E5B",
                  fontSize: 18,
                  display: "flex",
                  marginTop: 20,
                }}
              >
                <FaCookie></FaCookie>
                <div
                  className="blueTextOnHover"
                  style={{
                    color: "#697085",
                    fontSize: 16,
                    marginTop: "-3px",
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push("/cookie-policy");
                  }}
                >
                  Cookie Policy
                </div>
              </div>
            </Col>
          </Row>
          <div
            style={{
              // backgroundColor: "blue",
              borderTop: "1px solid #DFE3F2",
              marginTop: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
                fontSize: width > 450 ? 15 : 11,
                color: "#697085",
                marginTop: 8,
                // fontFamily: "Karla, sans-serif",
              }}
            >
              Copyright {new Date().getFullYear()} - All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
