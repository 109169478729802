import axios from "axios";
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  API_URL,
  GET_ERRORS,
  IS_LOADING,
  REGISTER_SUCCESS,
  UPDATE_LAYOUT,
} from "./types";
import { tokenConfig } from "../utils/tokenConfig";
import SuccessAlert from "../components/Modals/SuccessAlert";
import { menusPrimarySidebarAdmin } from "../utils/menusPrimarySidebarAdmin";
import { menusPrimarySidebarUser } from "../utils/menusPrimarySidebarUser";
import { menusSecondarySidebarAdmin } from "../utils/menusSecondarySidebarAdmin";
import { menusSecondarySidebarUser } from "../utils/menusSecondarySidebarUser";
import { getErrors } from "../utils/getErrors";
import ErrorAlert from "../components/Modals/ErrorAlert";

// CHECK TOKEN & LOAD USER
export const loadUser =
  (create_sidebar_menu = false) =>
  async (dispatch) => {
    try {
      // check if the token is valid and also user info
      const res = await axios.get(`${API_URL}/auth/user/`, tokenConfig());
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
      if (create_sidebar_menu === true) {
        // based on the user create the menus in the sidebar
        var menus_primary;
        var menus_secondary;
        if (res.data.is_staff === true) {
          menus_primary = menusPrimarySidebarAdmin;
          menus_secondary = menusSecondarySidebarAdmin;
        } else {
          menus_primary = menusPrimarySidebarUser;
          menus_secondary = menusSecondarySidebarUser;
        }
        dispatch({
          type: UPDATE_LAYOUT,
          payload: { key: "menus_primary", value: menus_primary },
        });
        dispatch({
          type: UPDATE_LAYOUT,
          payload: { key: "menus_secondary", value: menus_secondary },
        });
      }
    } catch {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const login = (username, password) => async (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // Request Body
  const body = JSON.stringify({ username, password });
  try {
    const res = await axios.post(`${API_URL}/auth/login/`, body, config);
    localStorage.setItem("token", res.data);
    dispatch(loadUser(true));
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    getErrors(err, dispatch);
  }
};

// LOGOUT
export const logout =
  (reload = true) =>
  async (dispatch) => {
    try {
      await axios.post(`${API_URL}/auth/logout/`, null, tokenConfig());
      dispatch({
        type: LOGOUT_SUCCESS,
      });
      if (reload === true) {
        window.location.reload();
      }
    } catch (err) {
      getErrors(err, dispatch);
    }
  };

// UPDATE INFO ACCOUNT
export const updateInfoAccount = (form) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    const res = await axios({
      method: "put",
      url: `${API_URL}/auth/user/`,
      data: form,
      ...tokenConfig(),
    });
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    dispatch({
      type: IS_LOADING,
    });
    SuccessAlert("Done!");
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const registrazioneUser =
  (username, password, token) => async (dispatch) => {
    try {
      if (token === "" || token === null || token === undefined) {
        ErrorAlert("Il link di registrazione non è valido");
      } else {
        dispatch({
          type: IS_LOADING,
        });
        const res = await axios.post(`${API_URL}/registrazione_user/`, {
          username: username,
          password: password,
          token: token,
        });
        dispatch({
          type: REGISTER_SUCCESS,
        });
        dispatch({
          type: IS_LOADING,
        });
      }
    } catch (err) {
      dispatch({
        type: IS_LOADING,
      });
      dispatch({
        type: LOGIN_FAIL,
      });
      getErrors(err, dispatch);
    }
  };

export const registrazioneStaff =
  (azienda, partitaIVA, username, email, password, history) =>
  async (dispatch) => {
    try {
      dispatch({
        type: IS_LOADING,
      });
      const res = await axios.post(`${API_URL}/registrazione_staff/`, {
        first_name: partitaIVA,
        last_name: azienda,
        username: username,
        email: email,
        password: password,
      });
      dispatch({
        type: REGISTER_SUCCESS,
      });
      dispatch({
        type: IS_LOADING,
      });
      history.push("/login");
      SuccessAlert(
        "Registrazione avvenuta con successo! Inserisci le tue credenziali per accedere."
      );
    } catch (err) {
      dispatch({
        type: IS_LOADING,
      });
      dispatch({
        type: LOGIN_FAIL,
      });
      getErrors(err, dispatch);
    }
  };

export const passwordResetRequest = (email) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    const res = await axios.post(`${API_URL}/password_reset/`, {
      email: email,
    });
    dispatch({
      type: IS_LOADING,
    });
    SuccessAlert(
      "Un link è stato inviato alla tua mail per recuperare la password"
    );
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const passwordReset = (password, token) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    const res = await axios.post(`${API_URL}/password_reset/confirm/`, {
      password: password,
      token: token,
    });
    dispatch({
      type: REGISTER_SUCCESS,
    });
    dispatch({
      type: IS_LOADING,
    });
    SuccessAlert("La password è stata aggiornata con successo");
    // dispatch(logout(false));
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};
export const contatti = (body) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    await axios.post(`${API_URL}/contatti/`, body);
    SuccessAlert("Richiesta inviata con successo! A breve ti ricontatteremo.");
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};
