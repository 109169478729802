import { UPDATE_TABLE_PROPERTY } from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_TABLE_PROPERTY:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          [action.payload.type]: action.payload.values,
        },
      };
    default:
      return state;
  }
}
